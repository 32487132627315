import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import LimitTextArea from '@/components/LimitTextArea';
import UploadCustom from '@/components/UploadCustom'
import { useSelector } from 'react-redux';
import { getter } from '@/store/reducers/Global';
import ButtonCustom from '@/components/ButtonCustom';
import { gethostIndex, styleToImg, uploadFile } from '@/store/reducers/index';
import { BASE_TU_BI_CHUAN, STYLE_TU_BI_CHUAN,COMMON_PLACEHOLDER } from '@/utils/toastTips';
import { fileToBase64, useValidate } from '@/utils/common';
import { Link} from 'react-router-dom';
import '@/assets/iconfont/iconfont.css'
const resetForm={ prompt: '',baseImg:null,styleImg:null}
const ConfigBox = (props: any) => {
  const [validate] = useValidate()
  const { uuid } = useSelector(getter)
  // const base
  const [form, setForm]:any = useState(
    { prompt: '',baseImg:null,styleImg:null,baseImgURL:'',styleImgURL:''}
  )
  const {beforeSubmit,submit} = props

  useEffect(() => {
   
    return () => {
    };
  }, [])

  const imageUpLoad = async(value: any,name:string) => {
    let url = await fileToBase64(value)
  
    setForm({...form,[name]:value,[`${name}URL`]:url})
  }
  const propmtChange = (value: any,name:string) => {
    console.log(value)
    setForm({...form,[name]:value})
  }
  
  const deleteImg = (name:string)=>{
    setForm({...form,[name]:null,[`${name}URL`]:''})
  }

  const submitDraw = async()=>{
    const ruleForm = {
      baseImg:{require:true,tips:BASE_TU_BI_CHUAN},
      styleImg:{require:true,tips:STYLE_TU_BI_CHUAN}
    }
    if(!validate(ruleForm,form)) return
    beforeSubmit()
    const newForm:any = {}
    newForm.uid = uuid
    newForm.prompt = form.prompt
    newForm.hostIndex = await gethostIndex()
    newForm.baseImg = await uploadFile(newForm.hostIndex,form.baseImg)
    newForm.styleImg = await uploadFile(newForm.hostIndex,form.styleImg)
    styleToImg(newForm).then((res:any)=>{
      submit(res)
      cleanForm()
    })
    
  }
  const cleanForm = ()=>{
    setForm(resetForm)
  }
  return (
    <div className="Style_page">
      <div className="classify_title">
        <Link to={'/DrawImage'}><i className='iconfont icon-fanhui'></i></Link >      
        <p>Style</p>
      </div>


      <div className="desc_box">
        <div className="desc_title">Base Image</div>
        <div className='desc_area'>
          <UploadCustom imageUrl={form.baseImgURL} deleteImg={()=>deleteImg('baseImg')} getUpLoadFile={(e:any)=>imageUpLoad(e,'baseImg')}></UploadCustom>
        </div>
      </div>

      <div className="desc_box">
        <div className="desc_title">Style Image</div>
        <div className='desc_area'>
          <UploadCustom imageUrl={form.styleImgURL} deleteImg={()=>deleteImg('styleImg')} getUpLoadFile={(e:any)=>imageUpLoad(e,'styleImg')}></UploadCustom>
        </div>
      </div>

      <div className="desc_box">
        <div className="desc_title">Description</div>
        <div className='desc_area'>
          <LimitTextArea onChange={(e:string)=>{propmtChange(e,'prompt')}} value={form.prompt} placeholder={COMMON_PLACEHOLDER}></LimitTextArea>
        </div>
      </div>

      <div className='submit' onClick={submitDraw}><ButtonCustom>Generate</ButtonCustom></div>



     </div>
  );
};

export default ConfigBox;

