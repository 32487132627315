import React, { useEffect, useRef, useState, useMemo } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMetadata } from '@/hooks/RouteMetaProvider';
import SliderCustom from '@/components/SliderCustom';
import Loading from '@/components/Loading';
import ImgControl from './ImgControl';

const WorkFrameBox = (props: any) => {
  const [size, setSize] = useState(30)
  useEffect(() => {

    return () => {
    };
  }, [])
  const imageSize = useMemo(() => {
    return 100 / Math.round((100 / size)) + "%"
  }, [size])
  const widthChange = (value: number) => {
    setSize(value)
  }

  const { list } = props
  return (
    <div className="img_show_list">
      <div className="frame_header">
        <div className="frame_header_title">Generations</div>
        <div className="frame_header_size">
          <div>Size:</div>
          <div className="slider"><SliderCustom onChange={widthChange} value={size} min={20} max={80} tooltip={{ open: false }}></SliderCustom></div>
        </div>
      </div>
      <div className="frame_warp">
        <div className="frame_content">
          {
            list.map((val: any) => {
              return <>
                {val.status == 2 ? <>
                  {/* 如果image还没生成出来不渲染img,生成占位符 */}
                  {
                   val.images&& val.images.map((img: string) => {
                      return <div className='image_box' style={{ width: `calc(${imageSize} - 10px)` }}>
                        <ImgControl {...props} infos={val} imgUrl={img} />
                      </div>
                    })
                  }
                </> : <div className='image_box' style={{ width: `calc(${imageSize} - 10px)` }}>
                  <div className="image_temphold">
                    <div className='loading'> <Loading imgName={'loading'}></Loading></div>

                  </div>
                </div>
                }
              </>

            })
          }

        </div>

      </div>
    </div>
  );
};

export default WorkFrameBox;

