import type { CSSProperties } from 'react';
import React, { useState } from 'react';
import { Button } from 'antd';
import './index.less';
import img1 from '@/images/home/switch1.jpg';
import img2 from '@/images/home/switch2.jpg';
import img3 from '@/images/home/switch3.jpg';
import img4 from '@/images/home/switch4.jpg';

import { ArrowRightOutlined } from '@ant-design/icons';
import ImageTouchChange from '@/components/ImageTouchChange';

const WorkCube: React.FC = () => {
   const [current, setCurrent] = useState(0)
   const imageShowList = [
      { front: require('@/images/home/switch3.jpg'), back: require('@/images/home/switch4.jpg') },
      { front: require('@/images/home/switch1.jpg'), back: require('@/images/home/switch2.jpg') },
   ]

   const changeImage = (index:number)=>{
      setCurrent(index)
   }

   return (
      <div className="WorkCube">
         <div className="hero_text">
            <h3>All in <span>One.</span></h3>
            <p>Your access to cutting-edge AI <br /> solutions in Web2 and in Web3.</p>
            <div className="flex jump">
               <Button href='/DrawImage' shape="round" className='started'>Get started<ArrowRightOutlined /></Button>
               {/* <Button href='' shape="round" className='demo'>Get demo</Button> */}
            </div>
         </div>
         <div className="hero_img">
            <div className="Switch_cube  animated fadeInDown">
               {/* <img src={imageShowList[current].front} alt="" /> */}
               <ImageTouchChange imgs={imageShowList[current]}></ImageTouchChange>
            </div>
            <div className="sample animated fadeInUp">
               {
                  imageShowList.map((val:any,index)=>{
                     return  <div onClick={(e)=>changeImage(index)}><img src={val.front} alt="" /></div>
                  })
               }
              
            </div>
         </div>
      </div>

   );
};

export default WorkCube;

