import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import LimitTextArea from '@/components/LimitTextArea';
import { useSelector } from 'react-redux';
import { getter } from '@/store/reducers/Global';
import ButtonCustom from '@/components/ButtonCustom';
import UploadCustom from '@/components/UploadCustom';
import { fileToBase64, useValidate } from '@/utils/common';
import { backgroundChange, gethostIndex, uploadFile } from '@/store/reducers/index';
import { message } from 'antd';
import { Link} from 'react-router-dom';
import '@/assets/iconfont/iconfont.css'
import { SHANG_CHUAN_WEN_JIAN, TI_JIAO_CHENG_GONG, TU_SHENG_TU_EXTRACT, TU_SHENG_TU_REPLACE } from '@/utils/toastTips';
const ConfigBox = (props: any) => {
  const [validate] = useValidate()
  const resetForm = { image: '', hostIndex: '', prompt: '', target: '', uid: 0 }
  const { uuid } = useSelector(getter)
  const [templateURL, setTemlateURL] = useState('')
  const [templateFile, setTempFile] = useState(null)
  const [form, setForm] = useState(
    { image: '', hostIndex: '', prompt: '', target: '', uid: 0 }
  )
  const {beforeSubmit,submit} = props
  useEffect(() => {

    return () => {
    };
  }, [])

  const getFile = (e: any) => {
    setTempFile(e)
    fileToBase64(e).then((res: any) => {
      setTemlateURL(res)
    })
  }


  const textChange = (value: string, name: string) => {
    console.log(name, value)
    setForm({ ...form, [name]: value })
  }
  const removeImg = () => {
    setTemlateURL('')
    setTempFile(null)
  }
  const resetFormFn = () => {
    setForm(resetForm)
    removeImg()
  }
  const submitDraw = async () => {
    const ruleForm = {
      target:  {require:true,tips:TU_SHENG_TU_EXTRACT},
      prompt: {require:true,tips:TU_SHENG_TU_REPLACE},
      templateFile:{require:true,tips:SHANG_CHUAN_WEN_JIAN}

    }
    const newForm = JSON.parse(JSON.stringify(form))
    if (!validate(ruleForm,{templateFile,...newForm})) return
    beforeSubmit()
    const hostIndex = await gethostIndex()
    const image = await uploadFile(hostIndex, templateFile)
    newForm.hostIndex = hostIndex
    newForm.image = image
    newForm.uid = uuid
    backgroundChange(newForm).then((res: any) => {
      resetFormFn()
      submit(res)
    })
  }


  return (
    <div className="config_box_back">
      <div className="classify_title">
        <Link to={'/DrawImage'}><i className='iconfont icon-fanhui'></i></Link >      
        <p>Background</p>
      </div>

      <div className="desc_box">
        <div className="desc_title">Base Image</div>
        <div className='desc_area'>
          <UploadCustom deleteImg={removeImg} imageUrl={templateURL} getUpLoadFile={(e: any) => getFile(e)}></UploadCustom>
        </div>
      </div>
      <div className="desc_box">
        <div className="desc_title">Description</div>
        <div className='desc_area' style={{marginBottom:'20px'}}>
          <LimitTextArea placeholder='Which parts would you like to cut?' value={form.target} onChange={(e: string) => textChange(e, 'target')}></LimitTextArea>
        </div>
        <div className='desc_area'>
          <LimitTextArea placeholder={'What would you like to replace it with?'} value={form.prompt} onChange={(e: string) => textChange(e, 'prompt')} shadowColor="#e1fadb"></LimitTextArea>
        </div>
      </div>
      <div className='submit' onClick={submitDraw} style={{marginTop:'40px'}}><ButtonCustom>Generate</ButtonCustom></div>
    </div>
  );
};

export default ConfigBox;

