import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import { Tabs,Button,Collapse} from 'antd';
import './index.less';
import ImageBox from './ImageBox/index'
import { useDispatch } from 'react-redux';

const ImageClassify = (props: any) => {
  const dispatch:any = useDispatch()
  useEffect(()=>{
  },[])
  return (
    <div className="tt_Image_page">
      <section className="padding-global padding-section-medium">

          <ImageBox/>

      </section>
      
      
    </div>


  );
};

export default ImageClassify;

