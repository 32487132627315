import * as global from '../Global'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch } from '@/utils/common'
import { resolve } from 'path';

interface textDraw {

}


type dataType ={
  modelList: any,
  textToImgList: {
    page: number,
    total: number,
    data: any
  },
  backgroundChange:{
    page: number,
    total: number,
    data: any
  },
  styleImgList:{
    page: number,
    total: number,
    data: any
  },
  SpecialIpList:{
    page: number,
    total: number,
    data: any
  },
  PoseGenerationgList:{
    page: number,
    total: number,
    data: any
  },
  textDraw: {
    model: '',
    height: number,
    width: number,
    prompt: '',
  }
}

export enum operateType {

}


export const getter = (state: any): dataType => {
  return state.index;
};

export const counterSlice = createSlice({
  name: 'Draw',
  initialState: {
    modelList: [],
    textDraw: {
      model: '',
      height: 0,
      width: 0,
      prompt: '',
    },
    textToImgList: {
      total: 0,
      page: 1,
      data: []
    },
    backgroundChange:{
      total: 0,
      page: 1,
      data: []
    },
    styleImgList:{
      total: 0,
      page: 1,
      data: []
    },
    SpecialIpList:{
      total: 0,
      page: 1,
      data: []
    },
    PoseGenerationgList:{
      total: 0,
      page: 1,
      data: []
    }
  } as dataType,
  reducers: {
    updateData(state, action: PayloadAction<{ key_name: any[]; value: any[] }>) {
      // 用法 dispatch(updateData({key_name:['xxx.xxx.xxx','xxx'],value:[yyy,kkk]}))
      const { key_name, value } = action.payload;
      key_name.forEach((key, index) => {
        const array = key.split(".")
        const last = array.pop();
        const ob = array.reduce((cur: any, next: string) => {
          return cur[next];
        }, state) || state;
        console.timeLog(last)
        ob[last] = value[index];
      })
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateData
} = counterSlice.actions;

export const getDrawList = (params: {style:string, page: number, listName:keyof dataType}  = {style:'',listName:'textToImgList', page: 1}) => async (dispatch: Function, getState: Function) => {
  return new Promise(async(resolve,reject)=>{
    const { listName='', page = 1, ...data } = params
    const state:any = getter(getState())
    const {uuid:uid} =getState().Global
    const newDrawList = JSON.parse(JSON.stringify(state[listName]))
    const res: any = await fetch({ method: 'get', url: '/comfyui/drawList', data: { uid,page, ...data } })
    newDrawList.page = page
    newDrawList.total = res.total
    if (page == 1) {
    newDrawList.data = res.list||[]
    }else{
      newDrawList.data.push(...res.list)
    }
    resolve(res)
    dispatch(updateData({ key_name: [listName], value: [newDrawList] }))
  })

}

export const getDrawDetail = (id: number) => async (dispatch: Function, getState: Function) => {
  const { textDraw } = getter(getState())
  const res: any = await fetch({ method: 'post', url: '/comfyui/getDrawInfo', data: { id } })
  console.log(res)
  // dispatch(updateData({key_name:['modelList'],value:[modelList]}))
}



export const gethostIndex: any = () => {
  // 获取服务器编号
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ method: 'get', url: '/comfyui/getHostIndex' })
    console.log(res)
    resolve(res)
  })
}
export const uploadFile: any = (hostIndex: number, file: File) => {
  // 上传文件
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/upLoadToComfy', data: { hostIndex, file } })
    resolve(res)
  })
}


export const DeleteImg: any = (ids: string, img: string) => {
  // 删除图片单张或者多张
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/drawDel', data: { ids, img } })
    resolve(res)
  })
}
export const optimizePrompt: any = (prompt: string) => {
  // 提交提示词优化
  return new Promise(async (resolve, reject) => {
     fetch({ url: '/chat/optimizePrompt', data: { prompt } }).then(res=>{
      resolve(res)
     }).catch(()=>{
      reject(false)
     })
  })
}
export const getOptimizePrompt: any = (id:string) => {
  // 提交提示词优化
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/chat/getOptimizeRes', data: { id },method:'get' })

    resolve(res)
  })
}

//背景切换绘画请求
export const backgroundChange: any = (params:{
  uid:'',
  prompt:string,
  target:string,
  image:string,
  hostIndex:number
}) => {
  // 背景切换
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/backgroundChange', data: params })
    resolve(res)
  })
}

//文生图绘画请求
export const textToImg: any = (params:{
  uid:'',
  prompt:string,
  width:number,
  height:number,
  seed:number,
  guidance:string,
  step:number,
  hostIndex:number,

}) => {
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/fluxTxtToImg', data: params })
    resolve(res)
  })
}

//风格转换绘画请求
export const styleToImg: any = (params:{
  uid:'',
  prompt:string,
  hostIndex:number,
  baseImg:string
  styleImg:string

}) => {
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/styleImg', data: params })
    resolve(res)
  })
}

//特殊ip转换
export const imgToSpecialIp: any = (params:{
  uid:'',
  prompt:string,
  hostIndex:number,
  baseImg:string
  styleImg:string

}) => {
  // 背景切换
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/specialIp', data: params })
    resolve(res)
  })
}
export const openposeToImg: any = (params:{
  uid:'',
  prompt:string,
  hostIndex:number,
  poseStyle :string,
  poseName :string,
  baseImg:string

}) => {
  // 背景切换
  return new Promise(async (resolve, reject) => {
    const res: any = await fetch({ url: '/comfyui/openposeToImg', data: params })
    resolve(res)
  })
}

export default counterSlice.reducer;
