import type { CSSProperties } from 'react';
import React from 'react';
import './index.less';
import Taber from './Taber/index';




const SwitchCube: React.FC = () => {


  return (
   <div className="SwitchCube">
     <div className="mainfeatures_heading">
          <div className="section-header text-align-center">
              <h2>HYPERION AI FEATURES</h2>
              <h3> Perfect features to <br /> make your work easier.</h3>
            </div>
            <div className="tabCube">
                <Taber/>
            </div>
        </div>

   </div>

  );
};

export default SwitchCube;

