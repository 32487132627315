import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch } from '@/utils/common';

export const counterSlice = createSlice({
  name: 'Global',
  initialState: {
    uuid:'',
    drawConfig:{
      txtToImgSet:{
        txtToImgModel:[],
        txtToImgSize:[],
        txtToImgShape:[]
      },
    specialIpSet:{}

    },
  },
  reducers: {
    //更该标签
    updateData(state,action: PayloadAction<{ key_name:any[]; value: any[] }>){
      const { key_name, value } = action.payload;
      key_name.forEach((key,index)=>{
        const array = key.split(".")
        const last = array.pop();
        const ob = array.reduce((cur:any,next:string)=>{
          return cur[next];
        },state) || state;
        console.timeLog(last)
        ob[last] = value[index];
      })
    },
  },
});

export const getter = (state: any) => {
  return state.Global;
};

// Action creators are generated for each case reducer function
export const {  updateData } = counterSlice.actions;

export const getDrawConfig = (style?:string) => async (dispatch: Function,getter:Function) => {
  const res: any = await fetch({ method: 'get', url: '/comfyui/webConfig',data:{style} })
  const txtToImgSet:any = res.txtToImgSet
  console.log(txtToImgSet)
  txtToImgSet['txtToImgModel'] = txtToImgSet.txtToImgModel.map((value:any)=>{return {label:value,value}})
  txtToImgSet['txtToImgShape'] = txtToImgSet.txtToImgShape.map((value:any)=>{return {label:value,value}})
  txtToImgSet['txtToImgSize'] = txtToImgSet.txtToImgSize.map((value:any)=>{return {label:value,value}})
  console.log(txtToImgSet)
  dispatch(updateData({ key_name: ['drawConfig','drawConfig.txtToImgSet',], value: [res,txtToImgSet] }))
}
export default counterSlice.reducer;
