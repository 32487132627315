import { useState } from 'react';  
  
// 辅助函数，用于复制文本到剪贴板  
function copyToClipboard(text:string) {  
  if (navigator.clipboard) {  
    // 使用现代API  
    return navigator.clipboard.writeText(text).then(() => true).catch(() => false);  
  } else {  
    // 回退到旧的API（不推荐，因为已被弃用）  
    const textarea = document.createElement('textarea');  
    textarea.value = text;  
    textarea.style.position = 'fixed'; // 防止滚动  
    document.body.appendChild(textarea);  
    textarea.focus();  
    textarea.select();  
    try {  
      const successful = document.execCommand('copy');  
      const msg = successful ? 'successful' : 'unsuccessful';  
      console.log('Fallback: Copying text command was ' + msg);  
      return successful;  
    } finally {  
      document.body.removeChild(textarea);  
    }  
  }  
}  
  
// 自定义Hook  
function useCopyToClipboard() {  
  const [isCopied, setIsCopied] = useState(false);  
  
  const copyText = async (text:string) => {  
    const success = await copyToClipboard(text);  
    setIsCopied(success);  
  };  
  
  return {  
    isCopied,  
    copyText,  
  };  
}  
export default useCopyToClipboard