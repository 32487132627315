import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import LimitTextArea from '@/components/LimitTextArea';
import UploadCustom from '@/components/UploadCustom'
import { useSelector } from 'react-redux';
import { getter } from '@/store/reducers/Global';
import ButtonCustom from '@/components/ButtonCustom';
import UploadStyle from '@/components/UoLoadStyle';
import { fileToBase64, useValidate } from '@/utils/common';
import { Modal } from 'antd';
import PoseSelect from './PoseSelect';
import { gethostIndex, openposeToImg, uploadFile } from '@/store/reducers/index';
import { FACE_TU_BI_CHUAN, POSE_TU_BI_CHUAN, COMMON_PLACEHOLDER } from '@/utils/toastTips';
import { Link } from 'react-router-dom';
import '@/assets/iconfont/iconfont.css'

const formBase = { poseStyle: '', pose: '', prompt: '', IMGbse64: '', baseImgFile: null }
const ConfigBox = (props: any) => {
  const [validate] = useValidate()
  const { uuid, drawConfig: { openposeToImgSet } } = useSelector(getter)
  const [poseModel, setPoseMode] = useState(false)
  const [form, setForm] = useState(
    formBase
  )
  const { beforeSubmit, submit } = props
  useEffect(() => {

    return () => {
    };
  }, [])

  const formChange = (value: string, name: string) => {
    setForm({ ...form, [name]: value })
  }

  const selecPose = (style: string, pose: string) => {
    setForm({ ...form, poseStyle: style, pose })
    setPoseMode(false)
  }
  const deletePose = (e: Event) => {
    e.stopPropagation()
    setForm({ ...form, poseStyle: '', pose: '' })

  }

  const getUploadFile = async (e: any) => {
    const IMGbse64: any = await fileToBase64(e)
    setForm({ ...form, IMGbse64, baseImgFile: e })
  }

  const deleteImg = () => {
    setForm({ ...form, IMGbse64: '', baseImgFile: null })

  }

  const submitDraw = async () => {
    const poseArr = form.pose.split('/')
    const ruleForm = {
      pose: { require: true, tips: POSE_TU_BI_CHUAN },
      baseImgFile: { require: true, tips: FACE_TU_BI_CHUAN }
    }
    if (!validate(ruleForm, { ...form })) return
    beforeSubmit()
    const newForm: any = {}
    const hostIndex = await gethostIndex()
    const image = await uploadFile(hostIndex, form.baseImgFile)
    newForm.hostIndex = hostIndex
    newForm.prompt = form.prompt
    newForm.poseStyle = form.poseStyle
    newForm.poseName = poseArr[poseArr.length - 1]
    newForm.baseImg = image
    newForm.uid = uuid
    openposeToImg(newForm).then((res: any) => {
      setForm(formBase)
      submit(res)
    })
  }

  return (
    <div className="PoseGeneration_page">
      <div className="classify_title">
        <Link to={'/DrawImage'}><i className='iconfont icon-fanhui'></i></Link >
        <p>Pose Generation</p>
      </div>

      <div className="desc_box">
        <div className="desc_title">Description</div>
        <div className='desc_area'>
          <LimitTextArea value={form.prompt} onChange={(e: string) => formChange(e, 'prompt')} placeholder={COMMON_PLACEHOLDER}></LimitTextArea>
        </div>
      </div>

      <div className="desc_box">
        <div className="desc_title">Pose Reference</div>
        <div className='desc_area' onClick={() => setPoseMode(true)}>
          <UploadStyle imageUrl={form.pose} deleteImg={deletePose}></UploadStyle>
        </div>
      </div>

      <div className="desc_box">
        <div className="desc_title">Face Reference</div>
        <div className='desc_area'>
          <UploadCustom imageUrl={form.IMGbse64} deleteImg={deleteImg} getUpLoadFile={(e: File) => { getUploadFile(e) }}></UploadCustom>
        </div>
      </div>

      <div className='submit' onClick={submitDraw}><ButtonCustom>Generate</ButtonCustom></div>

      <Modal
        style={
          {
            top: '20%',
            // transform: 'translate(0, -50%)'
          }
        }
        width={'600px'}
        open={poseModel}
        footer={null}
        mask={false}
        onCancel={() => setPoseMode(false)}
        closeIcon
      >
        <div style={{}}>

          <PoseSelect onChange={(poseStyle: string, pose: string) => selecPose(poseStyle, pose)} list={openposeToImgSet} />
          {/* <ImageEditor getImg={getImgObj} imgPath={imgPath.current} /> */}
        </div>
      </Modal>


    </div>
  );
};

export default ConfigBox;

