import React, { Children, useEffect, useRef, useState } from 'react';
import './style.less';


const ButtonCustom = (props:any) => {


  const {children,borderColor,background} = props

  return (
        <div  
        className={`btn_ctrl ${ borderColor ? "btn_ctrl_color" : ""}`}
        style={{...borderColor?{boxShadow:` 0 0 0 6px ${borderColor}`}:'',...background?{background:`${background}`}:''}}>
           <p>{children}</p>
        </div>
  );
};

export default ButtonCustom;

