import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteOutlined, FileTextOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined } from '@ant-design/icons';
import { Animated } from 'react-animated-css';
// import imgurl from '../../images/BTCbg.png'
const HistoryBox = (props: any) => {
  const [historyShow, setHistorySHow] = useState(false)
  const [historyShowBtnShow, setHistoryShowBtnShow] = useState(true)
  const historyList = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',]
  useEffect(() => {

    return () => {
    };
  }, [])
  const historyListShow = ()=>{
    setHistorySHow(true)
    setHistoryShowBtnShow(false)
  }
  const historyListHide = ()=>{
    setHistorySHow(false)
    setTimeout(() => {
    setHistoryShowBtnShow(true)
      
    }, 800);
  }
  return (
    <div className="history_box">
      {historyShowBtnShow ? <div className='open_history history_icon' onClick={historyListShow}><MenuFoldOutlined /></div> : false}
      <div className="history_contain" style={historyShow?{width:'250px'}:{}}>
        <div className="history_content" >
          <div className='hide_history history_icon' onClick={historyListHide}>
            <MenuUnfoldOutlined />
          </div>
          <div className="tips">Hisrory Chat</div>
          <div className='history_warp'>
            <div className='history_List'>
              {
                historyList.map((val: any) => {
                  return <div className="history_item">
                    <div className="item_icon"><FileTextOutlined /></div>
                    <div className='item_title line_ellipsis'>
                      z这是一个很长很长很长很长很长很长很长很长很长很长很长的标题
                    </div>
                    <div className="delete_icon"><DeleteOutlined /></div>
                  </div>
                })
              }
            </div>
          </div>
          <div className="create_btn">
            <div className='creat_icon'><PlusOutlined /></div>New Chat
          </div>
        </div>
      </div>
    </div>

  );
};

export default HistoryBox;

