import { configureStore } from '@reduxjs/toolkit';
import index from './reducers/index'
import Global from './reducers/Global'
const store = configureStore({
  reducer: {
    index,
    Global
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
