import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import YaberPane from '../YaberPane/index';
import './index.less';

const Taber = () => {
  const [activeKey,setActiveKey] = useState('1')

  const onChange = (key: string) => {
    console.log(key);
    setActiveKey(key)
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Text-to-Image',
      children: <YaberPane img={require('@/images/home/tab2.png')} HeadLine={'Text-to-Image'} Expound={'Enjoy an intuitive image generation experience that surpasses traditional platforms, offering a user-friendly interface for customizing AI-driven visuals while retaining advanced features for detailed control.'} href={'/DrawImage'} display={'flex'} />,
    },
    {
      key: '2',
      label: 'AI Assistant',
      children: <YaberPane img={require('@/images/home/tab1.png')} HeadLine={'AI Assistant'} Expound={'Easily combine and compare outputs from powerful Generative AI models, ensuring seamless integration across Web2 and Web3 platforms. Our AI Assistant provides intelligent, real-time support tailored to enhance your workflow in any environment.'} href={''} display={'none'}/>,
    }
  ];

  const CustomTabBar = (props: any) => {
    const { panes } = props
    return <div className='flex_tab' >
      {
        panes.map((val: any) => {
          return <div  className={`child_tab ${activeKey===val.props.tabKey?'active':''}`} onClick={()=>onChange(val.props.tabKey)} >{val.props.tab}</div>

        })
      }
    </div>

  };
 return  <Tabs 
    activeKey={activeKey}
    items={items}
    onChange={onChange}
    tabBarStyle={{ justifyContent: 'center' }}
    renderTabBar={(props, DefaultTabBar) => <CustomTabBar DefaultTabBar {...props} />}
  />
}

export default Taber;

