import React, { Children, useEffect, useRef, useState } from 'react';
import './style.less';
import useMouseCoords from '@/hooks/useMouseCoords';
import imgHandle from '@/images/home/slider_handle.png'

const ImageTouchChange = (props: any) => {
  const boxRef: any = useRef(null)
  const [startPoint,setStartPoin] = useState(0)
  const [boxWH, setBoxWH] = useState({ width: 0, height: 0 })

  const [dragLeft, setDragLeft] = useState(100)

  const [mouseIsDown, setMuseDown] = useState(false)


  const { imgs } = props
  useEffect(() => {
    setBoxWH({ width: boxRef.current.offsetWidth, height: boxRef.current.offsetHeight })
    setDragLeft(boxRef.current.offsetWidth/2)
  }, [])
  const handleMove = (e: any) => {
    e.preventDefault();
    if (mouseIsDown) {
    const offsetX = startPoint-e.screenX
      
      const prosition = dragLeft-offsetX
      const starP = startPoint-offsetX
      setStartPoin(starP)
      setDragLeft(prosition)
    }
  }
  const handleDown = (e:any) => {
    setStartPoin(e.screenX)
    setMuseDown(true)
  }
  const handleUp = () => {
    setMuseDown(false)
  }
  return (
    <div ref={boxRef} className='img_slider' onMouseUp={handleUp} onMouseLeave={handleUp} onMouseMove={handleMove}>
      <div className='back' style={{ backgroundImage: `url(${imgs.back})` }}></div>
      <div className='front' style={{ width: dragLeft }}>
        <div className='img_content' style={{ backgroundImage: `url(${imgs.front})`, ...boxWH }}></div>
      </div>
      <div className='handle' style={{ left: dragLeft }}>
        <div className='handle_bar'></div>
        <div className="handle_btn" onMouseDown={handleDown}>
          <img src={imgHandle} />
        </div>
      </div>
      {/* <div style={{ color: 'red', left: '50%',zIndex:1,position:'relative' }}> {prostion.x}-{prostion.y}</div> */}
    </div>

  );
};

export default ImageTouchChange;

