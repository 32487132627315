import { CloseCircleFilled, PlusOutlined, PictureOutlined } from "@ant-design/icons";
import { Col, InputNumber, Row, Select, Slider, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import React, { useRef, useEffect, forwardRef, useState } from "react";
import './style.less'
import '@/assets/iconfont/iconfont.css'

interface propsType {
  imageUrl?: string
  deleteImg?: Function
}

const UploadStyle = forwardRef((props: propsType, ref) => {
  const { imageUrl, deleteImg=()=>{} }:any = props
  useEffect(() => {

  }, []);
  return <div className={`upload_style ${imageUrl ? "upload_done" : ""}`}>
    <div className="upload_style_warp">
      {imageUrl ? <div className="show_image">
        <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        <div className="close_btn" onClick={deleteImg}>
          <CloseCircleFilled />
        </div>
      </div> : <div className="upload_in">
        <i className="iconfont icon-shangchuantupian" ></i>
      </div>}
    </div>

  </div>

});
export default UploadStyle

