import type { CSSProperties } from 'react';
import React from 'react';
import FlexCube from './FlexCube/index';
import './index.less';




const SmallFlexCube = (props:any) => {

  const ShowList = [
    {
      HeadLine: 'Advanced Language Models',
      matter: 'Analyze and contrast results from leading generative AI models(ChatGPT 4o, Claude 3.5 Sonnet), integrating advantages for enhanced text creation, evaluation, and strategic insights.',
      matter2:'',
      img:require('@/images/home/small1.png'),
      order:'1',
    },
    {
      HeadLine: 'Customize Flexible AI Art',
      matter: 'Discover our user-friendly AI image creation tool: easily design and customize flexible AI-generated images without the need for coding or server setup. Enjoy free GPU access for everyone.‍',
      matter2:'Provide a more advanced user experience than ComfyUI while maintaining the same functionality.',
      img:require('@/images/home/small2.png'),
      order:'-1',
    },
  ]

  return (
   <div className="SmallFlexCube">
       
       <div className="utility_header transform">
             <h4>this is how it works</h4>
             <h2>Where integration turns vision into reality.</h2>
           </div>
           <div className=''>
           {
                      ShowList.map(val=>{
                          return <div className='show_cube'>
                                    <FlexCube {...val}/>
                                 </div>
                      })
              }
            </div>             


   </div>

  );
};

export default SmallFlexCube;

