import React, { Children, useEffect, useRef, useState } from 'react';
import './style.less';
import Loading from '../Loading';


const LoadingWarp = (props: any) => {


  const { children,show } = props

  return (
    <div className='loading_warp'>
        {children}
        {show?<div className="loading_fixed_box" >
          <Loading imgName={'loading'}></Loading>
        </div>:false}
    </div>
  );
};

export default LoadingWarp;

