import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import { Tabs,Button,Collapse} from 'antd';
import './index.less';

interface propsType{
  children?:any,
  hover:string[],
  startColor ?:string,
  endColor? :string,
  direction? :string,
  className? :string,
  isText ?:boolean
}

const GradientRamp = ({
  children,
  hover=[],
  startColor = "#fff",
  endColor = "#5f5f5f",
  direction = "to right",
  className = "",
  isText = false
}:propsType) => {
  // 构造渐变背景字符串
  const backgroundImage = `linear-gradient(${direction}, ${startColor},${endColor})`;// 常规状态下,颜色变化
  const backgroundImageHover = `linear-gradient(${direction}, ${hover[0]},${hover[1]})`;// hover状态下,颜色变化
  const [styles,setStyles] = useState({
    backgroundImage,  
  });
  const mouseEnter = ()=>{
    if(hover.length){
      setStyles({backgroundImage:backgroundImageHover})
    }  
  }// hover状态下

  const mouseLeave = ()=>{
    setStyles({backgroundImage})
  }// hover离开时状态下

  return (
    <div className={`gradient-bg ${className}`} style={styles } onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}>
      {children}
    </div>
  );
  
};

// GradientRamp.css 实际上在这个场景中可能不再需要，因为所有样式都已内联到组件中
// 但如果你想要保持样式分离，可以只保留必要的部分


export default GradientRamp;

