import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import TextDraw from './page/TextDraw/index'
import { Tabs } from 'antd';
const Index = (props: any) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const editorRef: any = useRef(null)

  useEffect(() => {
    // causel()
    return () => {
    };
  })
  const tabChange = ()=>{

  }
  return (
    <div className="index">
      <Tabs defaultActiveKey="1" onChange={tabChange}>
        <TabPane tab="绘画" key="1">
        <TextDraw></TextDraw>
        </TabPane>
        <TabPane tab="绘画列表" key="2">
          {/* <DrawList /> */}
          <div>123</div>
        </TabPane>
      </Tabs>
      
    </div>
  );
};

export default Index;

