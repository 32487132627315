import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';
import { ArrowUpOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import ChatRecord from './ChatRecord';
// import imgurl from '../../images/BTCbg.png'
const ChatConTent = (props:any) => {
  const dispatch = useDispatch();
  const chatRecord = [{
    role:'user',
    content:'问一个小问题',
    name:'',
    header:'',
    id:1
  },{
    role:'answer',
    content:'回答一个小问题',
    name:'ChatGPT5.0',
    header:'',
    id:2
  },
  {
    role:'user',
    content:'问一个很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长问题',
    name:'',
    header:'',
    id:1
  },{
    role:'answer',
    content:'回答一个很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长问题',
    name:'ChatGPT5.0',
    header:'',
    id:2
  },
  {
    role:'user',
    content:'问一个很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长问题',
    name:'',
    header:'',
    id:1
  },{
    role:'answer',
    content:'回答一个很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长问题',
    name:'ChatGPT5.0',
    header:'',
    id:2
  }]
  useEffect(() => {
  
    return () => {    
    };
  },[])

  return (
    <div className="chat_main">
      <div className="chat_content">
        {
          chatRecord.length?
          <div className='chat_record'>
            <ChatRecord list={chatRecord} />
          </div>:
          <div className='empty_record'>
            <div className="record_model">Claudo 3.5 Sonnet</div>
            <div className="record_note">Chat with the most advanced models</div>
          </div>
        }
      </div>
      <div className="chat_input">
        <div className='input_box'>
          <div className='input_icon'><MessageOutlined /></div>
          <div className="input">
          <Input.TextArea maxLength={1500} className='inputarea' size="small" autoSize={{minRows:1,maxRows:5}} placeholder="Please feel free to ask questions" />

          </div>
          <div className='sent_btn'><ArrowUpOutlined /></div>
        </div>
      </div>
    </div>
  );
};

export default ChatConTent;

