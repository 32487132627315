import React, { useState } from 'react'
import './style.less'
import { fileToBase64 } from '@/utils/common'
import { DeleteImg } from '@/store/reducers/index'
import Loading from '@/components/Loading'
import { App, Popconfirm } from 'antd'
import { CHENG_GONG_SHAN_CHU, QING_QIU_SHAN_CHU } from '@/utils/toastTips'
import '@/assets/iconfont/iconfont.css'
interface propsType {
    imgUrl: string,
    infos: any,
    deleteBack?:Function
}
const ImgControl = (props: propsType) => {
    const [loadingShow, setLoadingShow]: any = useState(false)
    const {modal,message}:any = App.useApp();
    const {deleteBack = ()=>{}} = props
    const deleteImg= ()=>{
        modal.confirm({
            title:'Warning',
            content:QING_QIU_SHAN_CHU,
            icon:<></>,
            onOk:confirm
        })
    }
    const confirm = () => {

        setLoadingShow(true)
        DeleteImg(infos.id, imgUrl).then((res: any) => {
            message.success(CHENG_GONG_SHAN_CHU)
            setLoadingShow(false)
            deleteBack()
        })
    }
    const cancel = ()=>{

    }
    const { imgUrl, infos } = props
    return <div className='img_control'>
        <div className='img'>
            <img src={imgUrl} alt="" />
        </div>
        <div className="control_box">
   
               <div className='delete_img cube_flex' onClick={deleteImg}>
                   <div className="icon"><i className='iconfont icon-shanchu'></i></div>
                    <p>Delete</p>              
                </div>
            
                <div className='download_img cube_flex'>
                   <div className="icon"><i className='iconfont icon-xiazai'></i></div>
                   <a href={imgUrl.replace('http://121.8.169.179:12003', '')} target="_blank" download>Download</a> 
                </div>
        </div>
        {loadingShow ? <div className='imgloading'>
            <Loading imgName={'loading'}></Loading>
        </div> : false}
    </div>
}
export default ImgControl