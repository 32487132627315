import React, { useEffect } from 'react';
import './index.less';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { routes } from '@/route/index';
import { useDispatch } from 'react-redux';
import NavBar from './components/NavBar';
import BottomBar from './components/BottomBar'
import { RouteMetaProvider } from '@/hooks/RouteMetaProvider';
import { generateUUID, getLocalStorageItem, setLocalStorageItem } from '@/utils/common';
import { updateData } from '@/store/reducers/Global';
import { App } from 'antd';

function AppWarp() {
  const NavList = [
    { name: 'Home', path: '/home', children: [] },
    {
      name: 'AI Solutions', path: '', children: [
        { name: 'Text-to-Image', path: '/DrawImage', icon: 'icon-zhuti_tiaosepan' },
        { name: 'AI Assistant', path: '', icon: 'icon-bianji' },       
      ]
    },
    // { name: 'AIChatPage', path: '/chat/1', children: [] },
    // { name: 'Pricing', path: '/pricing', children: [] }
  ]
  const dispath = useDispatch()
  let uuid = getLocalStorageItem('uuid')
  if (!uuid) {
    uuid = generateUUID()
    setLocalStorageItem('uuid', uuid)
  }
  dispath(updateData({ key_name: ['uuid'], value: [uuid] }))
  useEffect(() => {

  }, [])


  return (
    <App>
      <Router>
        <Routes>
          {routes.map((route: any) => {
            console.log()
            const { key, Component, meta = {}, ...other } = route
            if(Component){
              return <Route
              Component={() => {
                return <RouteMetaProvider meta={meta}>
                  <div className="App" style={meta.hideFooter ? { height: '100vh' } : {}}>
                    <NavBar list={NavList} />
                    <div className='content_layout'>
                      <Component />
                    </div>
                    <BottomBar />
                  </div>

                </RouteMetaProvider>
              }
              }
              key={key}
              {...other} />;
            }else{
              console.log(other)
             return <Route key={key} {...other} />
            }
           
          })}

        </Routes>
      </Router>
    </App>
  );
}

export default AppWarp;
