import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMetadata } from '@/hooks/RouteMetaProvider';
// import ConfigBox from './components/Config';
import { getDrawConfig } from '@/store/reducers/Global';
import ImageShowList from '@/components/ImageShowList';
import { getDrawList, getter } from '@/store/reducers/index';
import { useParams } from 'react-router';
import TextToImageConfigBox from './components/TextToImage';
import SpecialIPStyleConfigBox from './components/SpecialIPStyle';
import StyleConfigBox from './components/Style';
import PoseGenerationConfigBox from './components/PoseGeneration';
import BackgroundChangeConfigBox from './components/BackgroundChange';
import { App } from 'antd';
import { TI_JIAO_CHENG_GONG } from '@/utils/toastTips';
import LoadingWarp from '@/components/LoadingWarp';

const AiChatPage = (props: any) => {
  // type value:TextToImage,PoseGeneration,Style,SpecialIPStyle,BackgroundChange

  const dispatch: any = useDispatch()
  const { message } = App.useApp()
  const [loadingShow,setLoadingShow] = useState(false)
  const { textToImgList, PoseGenerationgList, styleImgList, SpecialIpList, backgroundChange } = useSelector(getter)
  const { type }: any = useParams()
  let timer: any = useRef(null)
  useEffect(() => {
    dispatch(getDrawConfig())
  }, [])
  useEffect(() => {
    console.log(type)
    getList(1)
    return () => {
      clearTimeout(timer.current)
    };
  }, [type])

  const DrawImageConfig: any = {
    TextToImage: { listName: 'textToImgList', style: 'fluxTxtToImg', list: textToImgList.data, configBox: (props: any) => <TextToImageConfigBox {...props} /> },
    PoseGeneration: { listName: 'PoseGenerationgList', style: 'openposeToImg', list: PoseGenerationgList.data, configBox: (props: any) => <PoseGenerationConfigBox {...props} /> },
    Style: { listName: 'styleImgList', style: 'styleImgToImg', list: styleImgList.data, configBox: (props: any) => <StyleConfigBox {...props} /> },
    SpecialIPStyle: { listName: 'SpecialIpList', style: 'SpecialIp', list: SpecialIpList.data, configBox: (props: any) => <SpecialIPStyleConfigBox {...props} /> },
    BackgroundChange: { listName: 'backgroundChange', style: 'backgroundChange', list: backgroundChange.data, configBox: (props: any) => <BackgroundChangeConfigBox {...props} /> },

  }


  const getList = (page: number) => {
    dispatch(
      //@ts-ignore
      getDrawList({ page, listName: DrawImageConfig[type].listName, style: DrawImageConfig[type].style })).then((res: any) => {
        const hasProducting = res.list?.some((val: any) => val.status != 2)
        if (hasProducting) {
          timer.current = setTimeout(() => {
            getList(1)
          }, 5000);
        }

      })
  }
  const beforeSubmit = ()=>{
    if(loadingShow){
      return
    }
    setLoadingShow(true)
  }
  const submitDraw = (val: any) => {
    console.log('==', val)
    message.open({ content: TI_JIAO_CHENG_GONG })
    setLoadingShow(false)
    getList(1)
  }
  const ConfigBox = (props: any) => {
    return DrawImageConfig[type].configBox(props)
  }
  return (
    <div className="text_image">
      <div className="config">
        <LoadingWarp show={loadingShow}>
          <ConfigBox beforeSubmit={beforeSubmit} submit={submitDraw}></ConfigBox>

        </LoadingWarp>
      </div>
      <div className="work_frame">
        <ImageShowList deleteBack={() => { getList(1) }} list={DrawImageConfig[type].list}></ImageShowList>
      </div>
    </div>
  );
};

export default AiChatPage;

