

export const TI_JIAO_CHENG_GONG = 'Submitted, awaiting generation'; //提交成功提示
//图生图换背景提示
export const TU_SHENG_TU_EXTRACT = "extract can't not empty";
export const TU_SHENG_TU_REPLACE = "background can't not empty"; 
export const SHANG_CHUAN_WEN_JIAN = 'please upload image file'; 

//文生图提示
export const MIAO_SHU_BI_TIAN = "description can't not empty"

//风格转换提示
export const BASE_TU_BI_CHUAN="Please upload Base Image"//请上传图片
export const STYLE_TU_BI_CHUAN="Please upload Style Image"//请上传图片

//pose提示
export const POSE_TU_BI_CHUAN="Please select Pose Reference"//请选择姿势底图
export const FACE_TU_BI_CHUAN="Please upload Face Reference"//请上传图片

export const QING_QIU_SHAN_CHU="You'll delete this image. Continue?"
export const CHENG_GONG_SHAN_CHU="Delete success"//删除成功
//textarea提示语
export const COMMON_PLACEHOLDER="Describe your image."