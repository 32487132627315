import type { CSSProperties } from 'react';
import React from 'react';
import './index.less';

const ShowBox = (props:any) => {



  const { HeadLine,matter,img} = props

  return (
    <div className="ShowBox animated fadeInUp">
        <div className="icon-rect-medium">
          <img src={img} alt="" />
        </div>
        <div className="insights_text-wrapper">
          <div className="heading-style-h1">{HeadLine}</div>
          <div className="text-size-small">{matter}</div>
        </div>
    </div>
  );
};

export default ShowBox;

