import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import ModelAi from './components/Model';
import ChatConTent from './components/ChatMain';
import { useRouteMetadata } from '@/hooks/RouteMetaProvider';
import HistoryBox from './components/History';

const AiChatPage = (props:any) => {
  const meta = useRouteMetadata();
  console.log('12312312312313123',meta)
  useEffect(() => {
  
    return () => {    
    };
  },[])

  return (
    <div className="chat_page">
      <div className="model">
       <ModelAi></ModelAi>
      </div>
      <div className="chat">
        <ChatConTent></ChatConTent>
      </div>
      <div className="history">
        <HistoryBox></HistoryBox>
      </div>
    </div>
  );
};

export default AiChatPage;

