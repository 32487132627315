import React, { useEffect, useRef, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Tabs,Button,Collapse} from 'antd';
import './index.less';

import BigCard from '../BigCard/index';
import SmallCard from '../SmallCard/index';



const ImageBox = (props: any) => {

  const SmallList = [
    {
      HeadLine: 'Pose Generation',
      matter: 'Create or modify a pose based on an image.',
      img:require('@/images/drawing/ttl_2.png'),
      href:'/DrawImage/PoseGeneration',
      startColor:'#f0fff0',
      endColor:'#f9fce8',
      hover:['#e8fed0','#e8fed0']
    },
    {
      HeadLine: 'Style',
      matter: 'Modify the artistic style of an existing image with a reference image.',
      img:require('@/images/drawing/ttl_3.png'),
      href:'/DrawImage/Style',
      startColor:'#fff6e2',
      endColor:'#fbfce1',
      hover:['#fdf4c1','#fdf4c1']
    },
    {
      HeadLine: 'Special IP Style',
      matter: 'Generate images based on specific IP styles.',
      img:require('@/images/drawing/ttl_4.png'),
      href:'/DrawImage/SpecialIPStyle',
      startColor:'#ffe9fa',
      endColor:'#ffe7ea',
      hover:['#fecfe2','#fecfe2']
    },
    {
      HeadLine: 'Background',
      matter: 'Replace or modifies the background.',
      img:require('@/images/drawing/ttl_5.png'),
      href:'/DrawImage/BackgroundChange',
      startColor:'#f6efff',
      endColor:'#f0f0ff',
      hover:['#dad2fc','#dad2fc']
    },
  ]

  const navigate = useNavigate()

  const jump = (url:string)=>{
    console.log(123)
    navigate(url)
  }

  return (
    <div className="Image_list_page">
       <BigCard />

       {
          SmallList.map(val=>{
                 return <div className='show_cube' onClick={()=>jump(val.href)}>
                          <SmallCard   {...val}/>
                 </div>
          })
        }

    </div>


  );
};

export default ImageBox;

