import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import LimitTextArea from '@/components/LimitTextArea';
import CommonBlock from '@/components/CommonBlock';
import SelectCustom from '@/components/SelectCustom';
import { useSelector } from 'react-redux';
import { getter } from '@/store/reducers/Global';
import SliderInput from '@/components/SliderInput';
import { App, InputNumber, Switch } from 'antd';
import { Link } from 'react-router-dom';
import ButtonCustom from '@/components/ButtonCustom';
import { gethostIndex, getOptimizePrompt, optimizePrompt, textToImg } from '@/store/reducers/index';
import { MIAO_SHU_BI_TIAN, TU_SHENG_TU_REPLACE, COMMON_PLACEHOLDER } from '@/utils/toastTips';
import { useValidate } from '@/utils/common';
import '@/assets/iconfont/iconfont.css'
import Loading from '@/components/Loading';
const ConfigBox = (props: any) => {
  const { message } = App.useApp()
  let promptTimer: any = useRef(null)
  const [validate] = useValidate()
  const { drawConfig: { txtToImgSet }, uuid } = useSelector(getter)
  const [form, setForm] = useState(
    { prompt: '', model: '', size: '', shape: '', steps: 30, seed: 0, guidance: 7.5 }
  )
  const [fixed, setfixed] = useState(true)
  const [gettingPrompt, setGettingPrompt] = useState(false)
  const { submit, beforeSubmit } = props


  const imgWH = useMemo(() => {
    if (form.model && form.size && form.shape) {
      const WH = txtToImgSet.txtToImgWH[`${form.model}-${form.size}-${form.shape}`]
      return `${WH[0]}x${WH[1]}`

    } else {
      return ''
    }
  }, [form.model, form.size, form.shape])


  useEffect(() => {
    return () => {
      clearTimeout(promptTimer.current)

    }
  }, [])
  useEffect(() => {
    // console.log(txtToImgSet)
    if (!form.model || !form.size || !form.shape) {
      const defaultForm = JSON.parse(JSON.stringify(form))
      if (txtToImgSet.txtToImgModel[0]) {
        defaultForm.model = txtToImgSet.txtToImgModel[0].value
        defaultForm.size = txtToImgSet.txtToImgSize[0].value
        defaultForm.shape = txtToImgSet.txtToImgShape[0].value
        defaultForm.seed = getFixed()
        // defaultForm.imgWH = txtToImgSet.txtToImgWH[`${defaultForm.model}-${defaultForm.size}-${defaultForm.shape}`]
        setForm(defaultForm)
      }

    }
    return () => {
    };
  }, [txtToImgSet])



  const submitPrompt = async () => {
    if (!form.prompt) {
      message.error({ content: MIAO_SHU_BI_TIAN })
      return
    }
    if (gettingPrompt) {
      return
    }
    setGettingPrompt(true)
    optimizePrompt(form.prompt).then((id: any) => {
      getNewPrompt(id)

    }).catch((res: any) => {
      setGettingPrompt(false)
    })

  }
  const getNewPrompt = async (id: string) => {
    const res = await getOptimizePrompt(id)
    const prompt = res.answer
    if (!prompt) {
      promptTimer.current = setTimeout(() => {
        getNewPrompt(id)
      }, 3000);
    } else {
      setGettingPrompt(false)
      selectChange(prompt, 'prompt')
    }
  }
  const selectChange = (value: string, name: string) => {
    console.log(value, name)
    setForm({ ...form, [name]: value })
  }
  const changeFixed = () => {
    setForm({ ...form, seed: getFixed() })
  }
  const fixedOpen = (value: boolean) => {
    console.log(value)
    setfixed(value)
  }


  const getFixed = () => {
    return Math.floor(Math.random() * 1000000)
  }
  const submitDraw = async () => {
    const newForm: any = {}
    const ruleForm = {
      prompt: { require: true, tips: MIAO_SHU_BI_TIAN },

    }
    const WH = txtToImgSet.txtToImgWH[`${form.model}-${form.size}-${form.shape}`]
    newForm.uid = String(uuid)
    newForm.prompt = String(form.prompt)
    newForm.step = String(form.steps)
    newForm.seed = String(fixed ? form.seed : 0)
    newForm.guidance = String(form.guidance)
    newForm.width = String(WH[0])
    newForm.height = String(WH[1])
    if (!validate(ruleForm, newForm)) return
    beforeSubmit()
    newForm.hostIndex = await gethostIndex()
    clearTimeout(promptTimer.current)
    textToImg(newForm).then((res: any) => {
      submit()
      setForm({ ...form, prompt: '' })
    })
  }

  return (
    <div className="config_box">
      <div className="classify_title">
        <Link to={'/DrawImage'}><i className='iconfont icon-fanhui'></i></Link >
        <p>Text-to-Image</p>
      </div>
      <div className="desc_box">
        <div className="desc_title">Description</div>
        <div className='desc_area'>
          <LimitTextArea placeholder={COMMON_PLACEHOLDER} value={form.prompt} onChange={(e: string) => selectChange(e, 'prompt')}></LimitTextArea>
        </div>
        <div className='submit' style={{marginTop:'40px'}} onClick={submitPrompt}><ButtonCustom background="#7CD9AC" borderColor="#CAFCE1">{gettingPrompt ? <Loading imgName={'loading2'}>Waiting...</Loading> : 'Improve the prompt'}</ButtonCustom></div>
      </div>
      <div className="setting_box">
        <CommonBlock title="Settings">
          <div className='setting_block'>
            <div className='setting_style item'>
              <div className='params_title'>Style：</div>
              <div className='params_box' style={{ width: '110px' }}>
                <SelectCustom onChange={(e: string) => selectChange(e, 'model')} value={form.model} list={txtToImgSet.txtToImgModel}></SelectCustom>
              </div>
            </div>
            <div className='setting_size item'>
              <div className='params_title'>Size：</div>
              <div className='params_box' style={{ width: '110px' }}>
                <SelectCustom onChange={(e: string) => selectChange(e, 'size')} value={form.size} list={txtToImgSet.txtToImgSize}></SelectCustom>

              </div>
            </div>
            <div className='setting_shape item'>
              <div className='params_title'>Dimensions：</div>
              <div className='params_box' style={{ width: '110px' }}>
                <SelectCustom onChange={(e: string) => selectChange(e, 'shape')} value={form.shape} list={txtToImgSet.txtToImgShape}></SelectCustom>
              </div>
              <div className='imgWH'>{imgWH}</div>
            </div>
            <div className='setting_step item'>
              <div className='slider_title'>Compute Steps</div>
              <div className='slider_bar'>
                <SliderInput onChange={(e: string) => selectChange(e, 'steps')} min={1} max={40} value={form.steps}></SliderInput>
              </div>
            </div>
          </div>
        </CommonBlock>
      </div>
      <div className="advanced_box">
        <CommonBlock title="Advanced">
          <div className='advanced_fixed'>
            <div className='params_title'>fixed sec：</div>
            <div className='params_box'>
              <div className='switch'><Switch onChange={fixedOpen} checked={fixed} /></div>
              <div className='input'><InputNumber value={form.seed} disabled={!fixed} controls={false} min={0} step={1} size='small' type='number'></InputNumber></div>
              <div className={`random_icon ${!fixed ? 'disable_stype' : ''}`} onClick={changeFixed}><i className='iconfont icon-huopinfenliu
'></i></div>
            </div>
          </div>
          <div className='setting_step'>
            <div className='slider_title'>guidance scale</div>
            <div className='slider_bar'>
              <SliderInput onChange={(e: string) => selectChange(e, 'guidance')} max={10} min={0} step={0.1} value={form.guidance}></SliderInput>

            </div>
          </div>
        </CommonBlock>
      </div>
      <div className='submit' style={{ marginTop: '30px' }} onClick={submitDraw}><ButtonCustom>Generate</ButtonCustom></div>
    </div>
  );
};

export default ConfigBox;

