import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
// import imgurl from '../../images/BTCbg.png'
const ModelAi = (props: any) => {
  const dispatch = useDispatch();
  const AiModelList = [
    {
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    }
    ,{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    },{
      model: 'Claudo 3.5 Sonnet',
      icon: require('@/images/logo.png')
    }
  ]
  useEffect(() => {

    return () => {
    };
  }, [])

  return (
    <div className="model_box">
      <div className="tips">Model selection</div>
      <div className="model_warp">
      <div className="model_list">
        {
          AiModelList.map((val, index) => {
            return <div className={`model_item active`}>
              <div className="model_img">
                <img src={val.icon} alt="" />
              </div>
              <div className="model_name line_ellipsis">{val.model}</div>
            </div>
          })
        }

      </div>
      </div>
     
    </div>
  );
};

export default ModelAi;
