import { NavLink, Route, Routes, Navigate } from 'react-router-dom'
import Index from '@/views/Index/index';
import ImgEdt from '@/views/ImgEdtDemo/index';
import HomePage from '@/views/HomePage/index';
import AIChatPage from '@/views/ChatPage/index';
// import PricingPage from '@/views/PricingPage/index'
import ImageClassify from '@/views/ImageClassify/index'
import DrawImage from '@/views/DrawImage/index'

export const routes = [
  {
    path: '/',
    exact: true,
    Component: HomePage,
    key: 'Index',
  },
  {
    path: '/Index',
    exact: true,
    Component: Index,
    key: 'Index',
  },
 
  {
    path: '/ImgEdt',
    exact: true,
    Component: ImgEdt,
    key: 'ImgEdt',
  },
  {
    path: '/home',
    exact: true,
    Component: HomePage,
    key: 'HomePage',
  },
  {
    path: '/chat/:id',
    exact: true,
    Component: AIChatPage,
    key: 'AIChatPage',
    meta:{
      hideFooter:true
    }
  },
  {
    path: '/DrawImage',
    exact: true,
    Component: ImageClassify,
    key: 'ImageClassify',
  },
 
  {
    path: '/DrawImage/:type',
    exact: true,
    Component: DrawImage,
    key: 'DrawImage/:id',
    meta:{
      hideFooter:true
    }
  },
  // {
  //   path: '/pricing',
  //   exact: true,
  //   Component: PricingPage,
  //   key: 'PricingPage',
  //   meta:{
  //     hideFooter:true
  //   }
  // },

];
